<template>
  <section class="custom-background overflow-x-hidden">
    <a-spin :spinning="loading">
      <div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ＴＯＰ画面上のカルーセル -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <NFTCarousel
          class="carousel"
          :data="this.top_banners"
        ></NFTCarousel>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ キービジュアル３画像１行 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="width-1140-no-padding mx-auto hide-on-mobile add-margin-mobile">
          <div class="flex flex-wrap justify-between items-center">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ キービジュアル左端 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a href="https://zaif-ino.net/members-wallet-card/" target="_blank" class="w-full md:w-1/3 px-2 py-4">
              <img
                class="object-contain w-full h-auto"
                src="http://api.zaif-ino.com/static/bnr_small_zaifinowalletcard.jpg"
              />
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ キービジュアル左端 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ キービジュアル中央 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a href="https://zaif-ino.com/media/nft-manga/" target="_blank" class="w-full md:w-1/3 px-2 py-4">
              <img
                class="object-contain w-full h-auto"
                src="http://api.zaif-ino.com/static/bnr_small_manga.jpg"
              />
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ キービジュアル中央 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ キービジュアル右端 ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a href="https://zaif-ino.com/media/nft-card-business/" target="_blank" class="w-full md:w-1/3 px-2 py-4">
              <img
                class="object-contain w-full h-auto"
                src="http://api.zaif-ino.com/static/banner_small_nftcard.jpg"
              />
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ キービジュアル右端 ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ キービジュアル３画像１行 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--「カテゴリ」セクションタイトル -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="width-1140-no-padding mt-4 py-2 px-0 mx-auto">
          <div class="title custom-title">
            {{ $t('PROJECT_CATEGORY_SECTION_TITLE') }}
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--「カテゴリ」カルーセル -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <CategoriesCarousel
          class="mb-0 width-1140-no-padding mx-auto"
          :data="this.categories"
          :numberOfPicture="4"
        ></CategoriesCarousel>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--「お勧めプロジェクト」セクションタイトル  -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="width-1140-no-padding mt-4 py-2 px-0 mx-auto custom-background">
          <div class="title custom-title">
            {{ $t('PROJECT_RECOMMENDED_CREATOR_SECTION_TITLE') }}
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!--「お勧めプロジェクト」カルーセル -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <RecommendedProjectsCarousel
          class="mb-4 custom-background width-1140-no-padding mx-auto"
          :data="this.creators"
          :numberOfPicture="4"
        ></RecommendedProjectsCarousel>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ お勧めＮＦＴ一ページ分表示 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="width-1140-no-padding py-0 mx-auto custom-background">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--↓「NFT一覧」セクションタイトル & ステイタス選択コンボボックス↓-->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="home-breadcrumb flex width-1240 mx-auto">
            <!-- ----+---------+---------+---------+---------+---------+------ -->
            <!--「NFT一覧」セクションタイトル -->
            <!-- ----+---------+---------+---------+---------+---------+------ -->
            <div class="title custom-title text-top w-full">
              {{ $t('PROJECT_RECOMMENDED_NFT_SECTION_TITLE') }}
            </div>
            <!-- ----+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ ステイタス選択コンボボックス右端 ↓ -->
            <!-- ----+---------+---------+---------+---------+---------+------ -->
            <!--<div class="text-right text-top mt-3 md:top-0">-->
            <!--<div class="relative top-1.5 md:top-0">-->
            <!--
            2024/10/02wed ステイタス選択コンボボックス廃止。
            <div class="relative mt-3 top-1.5 md:top-0">
                <a-select
                v-model="selectedStatus"
                @change="handleChange(selectedStatus)"
                suffixIcon="▼"
                :showArrow="true"
              >
                <a-select-option
                  v-for="item in statuses"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ $t(item.key) }}
                </a-select-option>
              </a-select>
            </div>
            -->
            <!-- ----+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ ステイタス選択コンボボックス右端 ↑ -->
            <!-- ----+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!--↑「NFT一覧」セクションタイトル & ステイタス選択コンボボックス↑-->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「NFT一覧」１２画像 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div
            v-if="list && list.length > 0"
            class="mx-auto width-1140 mt-0"
          >
            <CardListNFT
              @onPageChange="onPageChange"
              :data="list"
            ></CardListNFT>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「NFT一覧」１２画像 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ お勧めＮＦＴが無い場合 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <a-empty
            class="mt-32"
            v-else
          ></a-empty>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ お勧めＮＦＴが無い場合 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ お勧めＮＦＴ一ページ分表示 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ お勧めＮＦＴの下の販促情報 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          v-if="localizedPromote"
          v-html="$sanitize(localizedPromote)"
          class="width-1140 mx-auto mt-10 text-center v-html-style"
        ></div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ お勧めＮＦＴの下の販促情報 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
    </a-spin>
  </section>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { PICTURE_PER_PAGE } from '@/constants/common.constant';
import { mapActions, mapState } from 'vuex';
import NFTCarousel from '@/components/nft/NFTCarousel.vue';
import CategoriesCarousel from '@/components/CarouselCategories.vue';
import RecommendedProjectsCarousel
                          from '@/components/CarouselCreators.vue';
// ＮＦＴページ表示に必要。
import CardListNFT from '@/components/nft/CardList/index.vue';
import { getTop_banners } from '@/services/top_banner';
import { getNotice, getPRFrame } from '@/services/projects';
import { getCategories } from '@/services/categories';
// import { SITE_URL } from "@/env";
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'page-home',
  // ====+=========+=========+=========+=========+=========+=========+=========E
  components: { NFTCarousel,
                CategoriesCarousel, RecommendedProjectsCarousel, CardListNFT
  },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      // statuses: [
      //   {
      //     key: 'SELECT_STATUS_ALL',
      //     value: 'ALL',
      //   },
      //   {
      //     key: 'SELECT_STATUS_COMING_SOON',
      //     value: 'COMING_SOON',
      //   },
      //   {
      //     key: 'SELECT_STATUS_ON_ALL_SALE',
      //     value: 'ON_ALL_SALE',
      //   },
      //   {
      //     key: 'SELECT_STATUS_FINISHED',
      //     value: 'FINISHED',
      //   },
      // ], // End of statuses: [...]
      // selectedStatus: 'ALL',
      loading: false,
      top_banners: [],
      notice: '',
      categories: [],
      creators: [],
      promote: '',
      page: 1,
    }; // End of return {...}
  }, // End of data() {...}
  // ==+=========+=========+=========+=========+=========+=========+=========E
  created() { // 2024/09/17tue現在、何もしない。
    // alert(`START: created: home.page.vue`);
    // 最初は、ステイタス選択コンボボックスの値は「全て」とする。
    // this.selectedStatus = 'ALL',
    // this.setStatusInHomePage('ALL');
    // alert(`END  : created: home.page.vue`);
  }, // End of created() {...}
  // ==+=========+=========+=========+=========+=========+=========+=========E
  destroyed() { // 2024/09/17tue現在、何もしない。
    // ページ離脱時にステイタス選択コンボボックスの値を初期化。
    // this.setStatusInHomePage('ALL');
  },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  metaInfo: {
    title: 'BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO',
    // meta: [
    //     {
    //         vmid: "description",
    //         property: "description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //     },
    //     {
    //         property: "og:title",
    //         content: "BCG・GameFi 専門のNFT1次販売ローンチパッド - Zaif INO",
    //         vmid: "og:title",
    //     },
    //     {
    //         property: "og:site",
    //         content: "Zaif INO",
    //         vmid: "og:site",
    //     },
    //     {
    //         property: "og:description",
    //         content:
    //             "ブロックチェーンゲーム・GameFi専門のNFT1次販売ローンチパッドのZaif INO。事前審査制導入で取り扱いNFTのクオリティを担保し、パブリックチェーンで海外プロジェクトの取り扱いも行います。",
    //         vmid: "og:description",
    //     },
    //     {
    //         property: "og:url",
    //         content: "zaif-ino.com/",
    //         vmid: "og:url",
    //     },
    //     {
    //         property: "og:site_name",
    //         content: "BCG・GameFi 専門のNFTローンチパッド（１次販売）- Zaif INO",
    //         vmid: "og:site_name",
    //     },
    //     {
    //         property: "og:image",
    //         content: `${SITE_URL}/ogp.png`,
    //         vmid: "og:image",
    //     },
    //     {
    //         name: "twitter:site",
    //         content: "https://mobile.twitter.com/zaif_ino",
    //         vmid: "twitter:site",
    //     },
    //     {
    //         name: "twitter:card",
    //         content: "summary",
    //         vmid: "twitter:card",
    //     },
    //     {
    //         name: "twitter:image",
    //         content: `${SITE_URL}/ogp.png`,
    //         vmid: "twitter:image",
    //     },
    // ],
  }, // metaInfo: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    ...mapState({
      // store にセットした、言語モード（日本語／英語）。
      locale: (state) => state?.setting.locale,
      // store にセットした、ステイタス選択コンボボックスの選択値。
      // string_status: (state) => state.extractcondition.string_status,
      // store にセットした、マーケットプレイスのＮＦＴ情報。
      list: (state) => state.marketplace.marketplaceList,
      //total: (state) => state.marketplace.totalItems || 0,
    }), // End of ...mapState({...})
    // ------+---------+---------+---------+---------+---------+---------+-----E
    localizedNotice() {
      return this.locale === 'en' ? this.notice.content : this.notice.content_jp;
    },
    // ------+---------+---------+---------+---------+---------+---------+-----E
    localizedPromote() {
      return this.locale === 'en' ? this.promote.content : this.promote.content_jp;
    },
  }, // End of computed: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  async mounted() {
    // alert(`START: mounted: home.page.vue`);
    await this.getTop_banners();
    await this.getNotice();
    await this.getCategories();
    await this.getCreators();
    // ヘッダのカテゴリ選択コンボボックスと検索テキストボックスを初期化。
    this.clearHeaderCondition();
    // store してあるステイタス値をステイタス選択コンボボックスの値にセット。
    // this.selectedStatus = this.string_status;
    // このページのNFT一覧を取得。
    // alert(`BEFORE fetchNewPage(page=${this.page}): mounted: home.page.vue`);
    await this.fetchNewPage(this.page);
    // alert(`AFTER  fetchNewPage(page=${this.page}): mounted: home.page.vue`);
    await this.getPRFrame();
    // alert(`END  : mounted: home.page.vue`);
  }, // End of async mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods: {
    ...mapActions({
      // store にある、ヘッダのカテゴリコンボボックスと検索テキストボックスの
      // 値をクリア。
      clearHeaderCondition: 'extractcondition/clearHeaderCondition',
      // home.page.vue のステイタス選択コンボボックスの値を store にセット。
      // setStatusInHomePage: 'extractcondition/setStatusInHomePage',
      // store にマーケットプレイスのＮＦＴ情報をセット。
      fetchMarketplace: 'marketplace/fetchMarketplaceList',
    }),// End of ...mapActions({...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // テーブル top_banner の全レコードを配列にしたものを取得。
    // 配列の要素は一レコードをオブジェクト化したもの。
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getTop_banners() {
      try {
        this.top_banners = await getTop_banners();
      } catch(error) {
        alert(error);
      } // End of async getTop_banners() {...}
    }, // End of async getTop_banners() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getNotice() {
      this.notice = await getNotice();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories からカテゴリ情報を
    // 取得し this.categories に取り込む。内容は一レコード一オブジェクトの配列。
    // カテゴリカルーセルに渡す。
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getCategories() {
      const {items: items} = await getCategories({
        type: 0, // カテゴリ。comic, art etc.
        recommendation_top_as_project: 1,
        is_deleted: 0, // 論理削除されていないカテゴリのみ取得。
      }); // End of const {items: items} = await getCategories({...})
      this.categories = items;
    }, // End of async getCategories() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // バックエンド mint-nft-backend DB テーブル categories からクリエイター情報
    // を取得し this.creators に取り込む。内容は一レコード一オブジェクトの配列。
    // おすすめプロジェクトカルーセルに渡す。
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getCreators() {
      const {items: items} = await getCategories({
        type: 1, // クリエイターを表す。
        recommendation_top_as_project: 1,
        is_deleted: 0, // 論理削除されていないクリエイターのみ取得。
      }); // End of const {items: items} = await getCategories({...})
      this.creators = items;
    }, // End of async getCreators() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ページが切り替わったら実行。
    // --+---------+---------+---------+---------+---------+---------+---------E
    async onPageChange(page) {
      this.page = page;
      await this.fetchNewPage(page);
    }, // End of async onPageChange(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ステイタス選択コンボボックスの選択肢が変更されたら実行。
    // --+---------+---------+---------+---------+---------+---------+---------E
    // async handleChange(selectedStatus) {
      // alert(`handleChange(selectedStatus=${selectedStatus}): home.page.vue`);
      // ステイタス選択コンボボックスの値を store にセット。
      // this.setStatusInHomePage(selectedStatus);
      // alert(`this.string_status=${this.string_status}: handleChange: home.page.vue`);
      // マーケットプレイスのＮＦＴ情報を store に取得。
      // await this.fetchNewPage(1);
    // }, // End of async handleChange(selectedStatus) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // マーケットプレイスのＮＦＴ情報を store に取得。
    // --+---------+---------+---------+---------+---------+---------+---------E
    async fetchNewPage(page) {
      // alert(`START: fetchNewPage(page=${page}): home.page.vue`);
      this.loading = true;
      await this.fetchMarketplace({
        limit : PICTURE_PER_PAGE,
        offset: PICTURE_PER_PAGE * (page - 1),
        search: '',
        // 2024/10/02wed ステイタス選択コンボボックス廃止。
        // status: this.string_status === 'ALL' ? undefined : this.string_status,
        status: undefined,
        hierarchy: undefined,
        recommendation_top: 1,
      }); // End of await this.fetchMarketplace({...}
      this.loading = false;
      // alert(`END  : fetchNewPage(page=${page}): home.page.vue`);
    }, // End of async fetchNewPage(page) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getPRFrame() {
      this.promote = await getPRFrame();
    },
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  scoped
  lang="scss"
>

/deep/ .v-html-style > p > a > img {
  margin: auto; display: block;
}

.overall{
  background-color:white !important;
}
.width-1140 {
  @apply px-2;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
}
.width-1140-no-padding {
  @screen xl {
    width: 1140px;
  }
}
.width-1240-no-padding {
  @screen xl {
    width: 1240px;
  }
}
.custom-background {
    background-color: white;
    color:black;
}

.carousel{
  height: auto;
}
.text-top {
  @apply text-2xl;
  @screen xxs {
    @apply text-3xl;
  }
  @screen md {
    @apply text-base;
  }
}
/* タイトル用の基本スタイル */
.title {
  display: block;
  font-size: 24px; /* フォントサイズを大きく */
  font-weight: bold; /* フォントを太字に */
  color: black; /* テキストカラー */
  margin-bottom: 20px; /* 下余白 */
  padding: 10px 10px; /* 上下パディング */
}

/* 特定のセクション用のカスタムスタイル */
.custom-title {
  /*background-color: #f0f0f0; 背景色 */
  border-radius: 8px; /* 角の丸み */
  text-align: left; /* テキストを中央揃えに */
}

.hide-on-mobile {
  display: block; /* デフォルトでは表示 */
}

.add-margin-mobile {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none; /* スマホサイズでは非表示 */
  }
  .add-margin-mobile + * {/*.add-margin-mobileクラスを持つ要素の直後にある要素に適用*/
    margin-top: 20px;
  }
}
.localizedNotice{
  border: 1px solid rgb(169, 169, 169);
  border-radius: 50px;
}

::v-deep .ant-breadcrumb {
  span {
    .ant-breadcrumb-link {
      @apply text-white;
      @apply font-medium;
    }
  }
}
::v-deep .ant-select {
  /* color: #fff; */
  color: blue;
  @apply font-semibold;
  &-arrow {
    color: black;
  }
  &-selection {
    background-color: transparent;
    border: 1px solid gray;
    @apply rounded-xl;
    @apply w-32 h-6;
    @apply text-xs;
    &__rendered {
      @apply mx-0;
      @apply w-full h-full;
      .ant-select-selection-selected-value {
        @apply w-full h-full text-center relative -top-1;
      }
    }
  }
}
</style>
