<template>
  <!-- --+---------+---------+---------+---------+---------+---------+------ -->
  <!-- ↓ 複数画面カルーセル ↓ -->
  <!-- --+---------+---------+---------+---------+---------+---------+------ -->
  <div class="carouselPluralPictures bg-transparent relative">
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--↓ 幅640px以上画面（PC等）で表示。幅640px未満（スマホ等）で非表示。↓-->
    <!--↓ 画像が4枚以下の場合                                             ↓-->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- bc-disabled で画面が無い時灰色になる -->
    <div v-if="data.length <= 4" class="hidden sm:block">
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 広い画面用カルーセル ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-carousel
        ref="carouselPC"
        class="mx-auto bg-disabled custom-background"
        :arrows="false"
        :dots="false"
        :slidesToShow="numberOfPicture"
      >
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 広い画面用カルーセルの（画像とキャプション）複数 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          v-for="(item, index) in numberOfPicture" :key="index"
          class="carousel-slide text-white text-lg relative h-full cursor-pointer"
        >
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 一枚の画像とキャプション ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="w-full h-full">
            <!-- データが存在する場合 -->
            <div v-if="data[index]">
              <!-- 一枚の画像 -->
              <img
                class="rounded-3xl cursor-pointer mb-3"
                @click="$router.push({ path: `/Category/${data[index].id}` })"
                @touchend="$router.push({ path: `/Category/${data[index].id}` })"
                :src="data[index].thumbnail_url"
              />
              <!-- キャプション -->
              <div class="custom-text flex justify-center">
                {{ localizedName(data[index]) }}
              </div>
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 一枚の画像とキャプション ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 広い画面用カルーセルの（画像とキャプション）複数 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </a-carousel>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 広い画面用カルーセル ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--↑ 幅640px以上画面（PC等）で表示。幅640px未満（スマホ等）で非表示。↑-->
    <!--↑ 画像が4枚以下の場合                                             ↑-->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--↓ 幅640px以上画面（PC等）で表示。幅640px未満（スマホ等）で非表示。↓-->
    <!--↓ 画像が5枚以上の場合。                                           ↓-->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div v-else class="hidden sm:block">
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 広い画面用カルーセル ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-carousel
        ref="carouselPC"
        class="mx-auto bg-disabled custom-background"
        :arrows="true"  
        :dots="false"
        :slidesToShow="numberOfPicture"
      >
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 広い画面用カルーセルの（画像とキャプション）複数 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          v-for="(item, index) in data" :key="index"
          class="carousel-slide text-white text-lg relative h-full cursor-pointer"
        >
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 広い画面用カルーセルの一枚の画像とキャプション ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="w-full h-full">
            <!-- 広い画面用カルーセルの一枚の画像 -->
            <img
              class="rounded-3xl cursor-pointer mb-3"
              @click="$router.push({ path: `/Category/${item.id}` })"
              @touchend="$router.push({ path: `/Category/${item.id}` })"
              :src="item.thumbnail_url"
            />
            <!-- 広い画面用カルーセルのキャプション -->
            <div class="custom-text flex justify-center">
              {{ localizedName(item) }}
            </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 広い画面用カルーセルの一枚の画像とキャプション ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 広い画面用カルーセルの（画像とキャプション）複数 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </a-carousel>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 広い画面用カルーセル ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--↑ 幅640px以上画面（PC等）で表示。幅640px未満（スマホ等）で非表示。↑-->
    <!--↑ 画像が5枚以上の場合。                                           ↑-->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--↓ 幅640px未満画面（スマホ等）で表示。幅640px以上（PC等）で非表示。↓-->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- bc-disabled で画面が無い時灰色になる -->
    <div class="visible w-7/12 h-full mx-auto sm:hidden">
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 狭い画面用カルーセル ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <a-carousel
        ref="carouselSmartPhone"
        class="mx-auto bg-disabled custom-background"
        arrows
        :afterChange="onChange"
        :dots="false"
        :slidesToShow=1
        :swipe="false"
        v-if="data && data.length > 0"
      >
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 狭い画面用カルーセルの（画像とキャプション）複数 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          v-for="item in data" :key="item.id"
          class="text-white text-sm relative h-full"
        >
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 狭い画面用カルーセルの一枚の画像とキャプション ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="w-full h-full mx-auto">
            <!-- 狭い画面用カルーセルの一枚の画像 -->
            <img
              class="rounded-3xl cursor-pointer flex mx-auto"
              @click="$router.push({path: `/Category/${item.id}`,})"
              @touchend="$router.push({path: `/Category/${item.id}`,})"
              v-if="item.thumbnail_url"
              :src="item.thumbnail_url"
            />
            <!-- 狭い画面用カルーセルのキャプション -->
            <div class="custom-text flex justify-center mt-3">{{ localizedName(item) }} </div>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 狭い画面用カルーセルの一枚の画像とキャプション ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 狭い画面用カルーセルの（画像とキャプション）複数 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </a-carousel>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 狭い画面用カルーセル ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!--↑ 幅640px未満画面（スマホ等）で表示。幅640px以上（PC等）で非表示。↑-->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ データが無い場合 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <a-empty
      v-if="!(data && data.length > 0)"
      class="empty-symbol"
    ></a-empty>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ データが無い場合 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </div>
  <!-- ↑ 複数画面カルーセル ↑ -->
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { mapState } from 'vuex';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'carouselPluralPictures',
  // ====+=========+=========+=========+=========+=========+=========+=========E
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    numberOfPicture: {
      type: Number,
      default: () => 1,
    },
  }, // End of props: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      slideIntervalPC: null,
      slideIntervalSmartPhone: null,
    }; // End of retgurn {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    ...mapState({
      // state にセットした、ヘッダのカテゴリ選択コンボボックスの選択値。
      object_category: (state) => state.extractcondition.object_category,
      // state にセットした言語モード（日本語モード／英語モード）。
      locale: (state) => state?.setting.locale,
    }),
  }, // End of computed: {...}
  // ==+=========+=========+=========+=========+=========+=========+=========E
  watch: {
      // ヘッダのカテゴリ選択コンボボックスの選択値が変わったら、
      // カルーセルの選択画像もそれに合わせる。
      // 例えば、「ゲーム」が選択されたら、スマホのカルーセル画面もゲームに
      // する。ＰＣのカルーセルは左端画像をゲームにする。
      object_category: function() {this.selectSlide()},
  }, // End of watch: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  mounted() {
    this.autoSlide();
  }, // End of mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods: {
    autoSlide() {
      //-+---------+---------+---------+---------+---------+---------+---------E
      // ↓ for PC ↓
      //-+---------+---------+---------+---------+---------+---------+---------E
      clearInterval(this.slideIntervalPC);
      this.slideIntervalPC = setInterval(() => {
        if (this.$refs?.carouselPC) {
          //alert(`this.$refs?.carouselPC=${this.$refs?.carouselPC._uid}`);
          //alert(`this.$refs?.carouselPC=${Object.keys(this.$refs?.carouselPC)}`);
          this.$refs.carouselPC.next();
        } // End of if (this.$refs?.carouselPC) {...}
      }, 100000000000000); // End of this.slideIntervalPC = setInterval(() => {...}...)
      //-+---------+---------+---------+---------+---------+---------+---------E
      // ↑ for PC ↑
      //-+---------+---------+---------+---------+---------+---------+---------E
      //-+---------+---------+---------+---------+---------+---------+---------E
      // ↓ for Smart Phone ↓
      //-+---------+---------+---------+---------+---------+---------+---------E
      clearInterval(this.slideIntervalSmartPhone);
      this.slideIntervalSmartPhone = setInterval(() => {
        if (this.$refs?.carouselSmartPhone) {
          this.$refs.carouselSmartPhone.next();
        }
      }, 100000000000000); // End of this.slideIntervalSmartPhone = setInterval(() => {...}...)
      //-+---------+---------+---------+---------+---------+---------+---------E
      // ↑ for Smart Phone ↑
      //-+---------+---------+---------+---------+---------+---------+---------E
    }, // End of autoSlide() {...}
    //---+---------+---------+---------+---------+---------+---------+---------E
    handleClick() {
      clearInterval(this.slideIntervalPC);
      clearInterval(this.slideIntervalSmartPhone);
      setTimeout(() => {
        this.autoSlide();
      }, 5000);
    }, // End of handleClick() {...}
    //---+---------+---------+---------+---------+---------+---------+---------E
    // カテゴリ名を、英語モードなら英語で、日本語モードなら日本語で返す。
    localizedName(item) {
      return this.locale === 'en' ? item.name_en : item.name_ja;
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    // カルーセルの表示画像を指定。ＰＣだと左端画像、スマホだと表示画像。
    //---+---------+---------+---------+---------+---------+---------+---------E
    selectSlide() {
      // ＰＣのカルーセル左端画像を指定。
      if (this.$refs?.carouselPC) {
          this.$refs.carouselPC.goTo(
              this.object_category.category_sequence, true
          );
      } // End of if (this.$refs?.carouselPC) {...}
      // スマホのカルーセル表示画像を指定。
      if (this.$refs?.carouselSmartPhone) {
          this.$refs.carouselSmartPhone.goTo(
              this.object_category.category_sequence, true
          );
      } // End of if (this.$refs?.carouselSmartPhone) {...}
    }, // End of selectSlide() {...}
  }, // methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
::v-deep.carouselPluralPictures {
  height: auto; /* カルーセルの高さ */
  @media (max-width: 768px) {
    height: auto;
  }
  font-family: 'Noto Sans CJK JP','Lato', 'Poppins', sans-serif;
  .ant-carousel {
    @screen md { /* 760px以上 */
      width: 100%;
    }
    @screen 3xl {
      width: 1366px;
    }
    height: 100%;
    .slick-slider {
      width: 100%;
      height: 100%;
    }
    .slick-prev, .slick-next {
      @apply bg-white; /* 背景色を白に設定 */

      @apply p-2; /* パディングを増やして四角形を形成 */
      @apply w-10 h-10; /* 四角形の幅と高さの調整 */
      @apply flex justify-center items-center; /* 中央に矢印を配置 */
      @apply border; /* 境界線を追加 */
      @apply border-black; /* 境界線の色を黒に */
      @apply rounded-md; /* 角を丸く */
      top:35%;
      background-color: black;
      z-index: 10;
      border-radius: 100000px;  /* 丸みを帯びた角の設定 */
    }
    .slick-prev:before, .slick-next:before {
      @apply text-lg; /* 矢印のサイズを大きくする場合、ここを調整 */
      color: rgb(255, 255, 255); /* 矢印の色を黒に設定 */
    }
    .slick-next:before {
      content: ">";
    }
    .slick-prev:before {
      content: "<";
    }
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide div div img {
          height: auto;
          width: auto;
        }
      }
      .slick-slide,
      .slick-slide > div {
        height: 100%;
      }
      .carousel-slide {
        padding-left: 20px;  /* 左のスペース */
        padding-right: 20px; /* 右のスペース */
      }
      .carousel-img {
        overflow: hidden;  /* はみ出した画像を隠す */
        border: 0.3px solid black;  /* ボーダーを追加 */
      }
    }
  }
  .empty-symbol {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .tba-time {
    @screen 2xl {
      min-width: 245px;
    }
  }
  .property {
    min-width: 120px;
  }
  .custom-background {
    background-color:white;
  }
  .custom-text{
    color: black;
    font-size: 18px;
    font-weight: bold;
  }

  .sale-section {
    @apply xxs:px-8 pr-8;
    &-title {
      @apply text-xl font-semibold;
    }
  }
}
</style>
